<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-reconciliation-task-status-change-dialog"
    @close="onDialogClose"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-reconciliation-task-status-change-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <div class="elv-reconciliation-delete-task-message-box-content">
      <div class="elv-reconciliation-delete-task-message-box-content-custom">
        <slot name="customContent"></slot>
      </div>
      <div v-if="showAgreeInfo" class="elv-reconciliation-delete-task-message-box-content-agree-info">
        <el-checkbox v-model="agree" />
        <span>{{ t('reconciliation.removeReconciliationJournalsDataTip') }}</span>
      </div>
    </div>
    <template #footer>
      <el-button
        type="info"
        round
        class="elv-reconciliation-task-status-change-dialog-footer__cancel"
        @click="onCloseDialog"
        >{{ props.cancelText || t('button.cancel') }}</el-button
      >
      <el-button
        type="primary"
        round
        class="elv-reconciliation-task-status-change-dialog-footer__delete"
        :loading="loading"
        @click="onConfirmChangeStatus"
        >{{ props.confirmText || t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: ''
  },
  confirmText: {
    type: String,
    default: ''
  },
  cancelText: {
    type: String,
    default: ''
  },
  showAgreeInfo: {
    type: Boolean,
    default: true
  }
})

const { t } = useI18n()
const show = defineModel('show', { type: Boolean, required: true })
const agree = defineModel('agree', { type: Boolean, required: false })
const loading = defineModel('loading', { type: Boolean, required: false })

const emit = defineEmits(['onConfirmChangeStatus', 'onCloseDialog'])

const onConfirmChangeStatus = () => {
  emit('onConfirmChangeStatus')
}

const onCloseDialog = () => {
  emit('onCloseDialog')
}

const onDialogClose = () => {
  agree.value = false
}
</script>

<style lang="scss">
.elv-reconciliation-task-status-change-dialog {
  width: 560px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;

    .elv-reconciliation-task-status-change-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 23px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-reconciliation-delete-task-message-box-content {
      display: flex;
      flex-direction: column;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 400;
      color: #1e2024;

      .elv-reconciliation-delete-task-message-box-content-custom {
        font-size: 12px;
        color: #636b75;
        line-height: 18px;

        p {
          margin-bottom: 16px;
        }
      }

      .elv-reconciliation-delete-task-message-box-content-agree-info {
        display: flex;
        align-items: center;

        .el-checkbox {
          margin-right: 2px;
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-reconciliation-task-status-change-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }

    .elv-reconciliation-task-status-change-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
